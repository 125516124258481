import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import {connect, useSelector} from 'react-redux';
import { 
  EditOutlined, 
  SettingOutlined, 
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { API_BASE_URL } from "configs/AppConfig";
import setLocale from "constants/Translate";

const menuItem = [
	{
		title: setLocale(true,"topnav.edtiProfile"),
		icon: EditOutlined ,
		path: "/panel/profile"
    },
    
    {
		title: setLocale(true,"topnav.Accounts"),
		icon: SettingOutlined,
		path: "/panel/accounts/trading-accounts"
    },
    {
		title: setLocale(true,"topnav.Billing"),
		icon: ShopOutlined ,
		path: "/panel/billing"
	},
    {
		title: setLocale(true,"topnav.helpCenter"),
		icon: QuestionCircleOutlined,
		path: "/panel/support/faq"
	}
]

export const NavProfile = ({signOut}) => {
  const user = useSelector((state)=>state.user.user);

  const getProfilenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div style={{minWidth: 45}}>
              <Avatar size={45} src={user?.avatar ? API_BASE_URL + user.avatar : '/img/others/avatar.svg'} />
          </div>
          <div className="pl-3">
            <h4 className="mb-0">{user?.first_name + ' ' + user?.last_name}</h4>
            <h6 className="mb-1">{setLocale(true,"Client Number")} : {user?.clientNumber}</h6>
            {
            user &&
              <span className={`tag p-1 ${user?.verified ? 'ant-tag-success': 'ant-tag-error'}`}>
                {user?.verified ? setLocale(true,"Verified") : setLocale(true,"Unverified")}
              </span>
            }
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">
                {setLocale(true,"topnav.signOut")}
              </span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={getProfilenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={user?.avatar ? API_BASE_URL + user.avatar : '/img/others/avatar.svg'} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, {signOut})(NavProfile)
