import {get, post, put, remove} from './requests';
import {
  GET_LEVERAGE_API,
  GET_ACCOUNTS_TYPE_API,
  ACCOUNT_API,
  POST_ACCOUNT_DEPOSIT_API,
  POST_ACCOUNT_WITHDRAWAL_API,
  POST_UPDATE_ACCOUNT_LEVERAGE_API,
  POST_RESET_ACCOUNT_PASSWORD_API,
  POST_CHANGE_ACCOUNT_PASSWORD_API,
  GET_ACCOUNT_OPEN_POSITIONS,
  GET_ACCOUNT_DEALS_HISTORY,
  TRANSACTIONS_ACCOUNT_TO_USER_API,
  SYNC_WITH_META_API,
} from 'constants/ApiConstant';

export const getLeverage = (id) => {
  const url = GET_LEVERAGE_API(id);
  return get(url);
};

export const getAccountTypes = (data) => {
  let url = GET_ACCOUNTS_TYPE_API();
  if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
  return get(url);
};

export const createAccount= (data) => {
  const url = ACCOUNT_API();
  return post(url, data);
};

export const deleteAccount = (id) => {
  let url = ACCOUNT_API(id);
  if (id) { url += `${id}` }
  return remove(url);
}

export const getAccountData = (id, data) => {
  let url = ACCOUNT_API();
  if (id) { url += `${id}` }
  if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
  return get(url);
};

export const accountDeposit = (data) => {
  const url = POST_ACCOUNT_DEPOSIT_API();
  return post(url, data);
};

export const accountWithdrawal = (data) => {
  const url = POST_ACCOUNT_WITHDRAWAL_API();
  return post(url, data);
};

export const accountToUser = (data) => {
  const url = TRANSACTIONS_ACCOUNT_TO_USER_API();
  return post(url, data);
};

export const updateAccountLeverage = (id, data) => {
  const url = POST_UPDATE_ACCOUNT_LEVERAGE_API(id);
  return put(url, data);
};

export const resetAccountPassword = (data) => {
  const url = POST_RESET_ACCOUNT_PASSWORD_API();
  return post(url, data);
};

export const changeAccountPassword = (data) => {
  const url = POST_CHANGE_ACCOUNT_PASSWORD_API();
  return post(url, data);
};

export const getAccountOpenPositions = (login) => {
  const url = GET_ACCOUNT_OPEN_POSITIONS(login);
  return get(url);
};

export const getAccountDealsHistory = (login, filters) => {
  let url = GET_ACCOUNT_DEALS_HISTORY(login);
  url += `from=${filters.from}&to=${filters.to}`;
  return get(url);
};

export const getSyncWithMeta = () => {
  let url = SYNC_WITH_META_API();
  return post(url);
};