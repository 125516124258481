import React, { useEffect } from "react";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import Routers from './Routers';
import {store, persistor} from './redux/store';
import { initializeGtag } from './utils/gtag';
import { THEME_CONFIG } from './configs/AppConfig';
import { PUBLIC_RECAPTCHA_KEY } from 'configs/AppConfig';
import 'assets/css/antdCustom.css';
const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const App = () => {
  useEffect(() => {
    // Initialize Google Analytics
    initializeGtag();
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GoogleReCaptchaProvider
            useRecaptchaNet
            reCaptchaKey={PUBLIC_RECAPTCHA_KEY} // Ensure this variable is defined
            scriptProps={{ async: true, defer: true, appendTo: 'body' }}
          >
            <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point"> {/* Ensure 'themes' and 'THEME_CONFIG' are defined */}
              <Routers/>
            </ThemeSwitcherProvider>
          </GoogleReCaptchaProvider>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;