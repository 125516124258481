import {get, post, put, remove} from './requests';
import {
  USER_AVATAR_API,
  USER_PROFILE_API,
  USER_DOCUMENTS,
  GET_SETTING_FUNDING_METHOD_API,
  PAYMENT_METHOD_API,
  POST_CHANGE_USER_PASSWORD_API,
  GET_SECURITY_PIN_API,
  GET_TWO_FACTOR_ENABLE_API,
  GET_TWO_FACTOR_DISABLE_API,
  POST_TWO_FACTOR_VERIFY_API,
  POST_TWO_FACTOR_LOGIN_API,
  UPDATE_USER_CONTRACT_API
} from "constants/ApiConstant";

export const uploadUserAvatar = (data) => {
  const url = USER_AVATAR_API();
  var formData = new FormData();
  formData.append("files.avatar", data);
  formData.append("data", "{}");
  const contentType = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return put(url, formData, contentType);
};

export const deleteUserAvatar = () => {
  const url = USER_AVATAR_API();
  return remove(url);
};

export const updateProfile = (data) => {
  const url = USER_PROFILE_API();
  return put(url, data);
};

export const updateUserContract = (data) => {
  const url = UPDATE_USER_CONTRACT_API();
  return put(url, data);
};

export const getProfile = () => {
  const url = USER_PROFILE_API();
  return get(url);
};

export const getUserDocuments = () => {
  const url = USER_DOCUMENTS();
  return get(url);
};

export const userSendDocuments = (data) => {
  var formData = new FormData();
  data.forEach((item) => {
    item.files.forEach((file) => {
      formData.append("files." + item.type, file);
    });
  });
  formData.append("data", "{}");
  const url = USER_DOCUMENTS();
  const contentType = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return post(url, formData, contentType);
};

export const fundingMethodSettingGet = () => {
  const url = GET_SETTING_FUNDING_METHOD_API();
  return get(url);
};

export const newPaymentMethodPost = (data) => {
  const url = PAYMENT_METHOD_API();
  return post(url, data);
};

export const paymentMethodsGet = () => {
  const url = PAYMENT_METHOD_API();
  return get(url);
};

export const changeUserPassword = (data) => {
  const url = POST_CHANGE_USER_PASSWORD_API();
  return post(url, data);
};

export const securityPinGet = (data) => {
  const url = GET_SECURITY_PIN_API();
  return get(url);
};

export const enableTwoFactor = () => {
  const url = GET_TWO_FACTOR_ENABLE_API();
  return get(url);
};

export const disableTwoFactor = (data) => {
  const url = GET_TWO_FACTOR_DISABLE_API();
  return post(url, data);
};

export const verifyTwoFactor = (data) => {
  const url = POST_TWO_FACTOR_VERIFY_API();
  return post(url, data);
};

export const loginTwoFactor = (data) => {
  const url = POST_TWO_FACTOR_LOGIN_API();
  return post(url, data);
};
