const FirebaseConfig = {
  apiKey: "AIzaSyD-apsbfLoPytjUnCIjupFHmp2kVws4U8o",
  authDomain: "localhost-40132.firebaseapp.com",
  projectId: "localhost-40132",
  storageBucket: "localhost-40132.appspot.com",
  messagingSenderId: "231956573347",
  appId: "1:231956573347:web:e13bc2fb9cf7b3f8312316",
  measurementId: "G-37NRNCL9YL"
};

export default FirebaseConfig