import React, { Component } from "react";
import Views from './views';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {getProfile, paymentMethodsGet} from "./apis/profile";
import {setPaymentMethods, setUserData, setUserId} from "./redux/actions/User";
import {signOut} from "./redux/actions/Auth";
import {getSyncWithMeta} from "./apis/account";

class Routers extends Component {
    async  componentDidMount() {
        const token = this.props.auth.token;
        const { dispatch } = this.props;

        if(token){
            axios.defaults.headers.common.Authorization = 'Bearer ' + token;

            await getProfile().then((result)=>{
                dispatch(setUserData(result.data))
                dispatch(setUserId(result.data.id))
            }).catch((error)=>{
                if(error.response.status === 401){
                    dispatch(signOut())
                }
            });

            await paymentMethodsGet().then((result)=>{
                dispatch(setPaymentMethods(result.data))
            }).catch((error)=>{
                if(error.response.status === 401){
                    dispatch(signOut())
                }
            });

            await getSyncWithMeta().catch(()=>{});
        }
    }
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/" component={Views}/>
                </Switch>
            </Router>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(Routers);
