import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import user from "./User";
import vps from "./Vps";

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    user: user,
    vps: vps
});

export default reducers;