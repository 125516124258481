import React from 'react';
import { APP_NAME } from 'configs/AppConfig';
import setLocale from 'constants/Translate';


export default function Footer() {
	return (
		<>
			<footer className="footer">
				<span>
					{setLocale(true,"Copyright.text.part1")}  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> {setLocale(true,"Copyright.text.part2")}
					<span className="mx-2 text-muted"> | </span>
					<a className="text-gray" href="#" target="_blank" rel="noreferrer" onClick={e => e.preventDefault()}>{setLocale(true,"Terms.text")}</a>
					<span className="mx-2 text-muted"> | </span>
					<a className="text-gray" href="#" target="_blank" rel="noreferrer" onClick={e => e.preventDefault()}>{setLocale(true,"Privacy.text")}</a>
				</span>
				
			</footer>
		</>
	)
}

