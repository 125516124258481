
export const initializeGtag = () => {
  
  window.dataLayer = window.dataLayer || [];
  function gtag(){ window.dataLayer.push(arguments); }
  // Create a script element
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-72HHHV140J';
  
  // Append the script to the head
  document.head.appendChild(script);

  // Initialize gtag
  gtag('js', new Date());
  gtag('config', 'G-72HHHV140J');
};
