import {
    SET_USER_DATA,
    SET_AUTHENTICATED,
    SET_PAYMENT_METHODS,
    SET_ROLE,
    SET_USER_ID,
    SET_LANG
} from '../constants/User';

const initState = {
    user: {},
    isAuthenticated: false,
    paymentMethods: null,
    role: null,
    lang: null,
    userID: null
}

const userData = (state = initState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                user: action.payload,
            }
        case SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: action.payload
            }
        case SET_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: action.payload
            }
        case SET_ROLE:
            return {
                ...state,
                role: action.payload
            }
        case SET_USER_ID:
            return {
                ...state,
                userID: action.payload
            }
        case SET_LANG:
            return {
                ...state,
                lang: action.payload
            }
        default:
            return state;
    }
}

export default userData