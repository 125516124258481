import axios from 'axios';

const signOut = () => {
    window.location.reload();
}

export const get = async (url, config) => {
    return await axios.get(url, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response.status === 401) {
            signOut()
        }
        return Promise.reject(error)
    });
}

export const post = async (url, data, config) => {
    return await axios.post(url, data, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response.status === 401) {
            signOut()
        }
        return Promise.reject(error)
    });
}

export const put = async (url, data, config) => {
    return await axios.put(url, data, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response.status === 401) {
            signOut()
        }
        return Promise.reject(error)
    });
}

export const remove = async (url, config) => {
    return await axios.delete(url, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response.status === 401) {
            signOut()
        }
        return Promise.reject(error)
    });
}