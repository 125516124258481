import { API_BASE_URL, EXTREME_IP_KEY, MARKET_ANALYSIS_BASE_URL, MARKET_KEY } from 'configs/AppConfig';

// ****************************** Static ****************************** //
// General
export const GRAPHQL = () => `${API_BASE_URL}/graphql`;
export const GET_SETTING_API = () => `${API_BASE_URL}/setting`;
// export const GET_COUNTRY_IP_API = () => `https://extreme-ip-lookup.com/json/?key=${EXTREME_IP_KEY}`;
export const GET_COUNTRY_IP_API = () => `https://api.ipgeolocation.io/ipgeo?apiKey=${EXTREME_IP_KEY}`;
export const TRADINGVIEW_WIDGET_API = () => `https://s3.tradingview.com/tv.js`;

// Upload File
export const POST_UPLOAD_API = () => `${API_BASE_URL}/upload`;

// Fortuna Investments
export const GET_MARKET_API =() => `${MARKET_ANALYSIS_BASE_URL}/v1/symbols?accessKey=${MARKET_KEY}`;

// ****************************** Authentication**************************************** //
// 
export const POST_LOGIN_API = () => `${API_BASE_URL}/portal/login`;
export const POST_REGISTER_API = () => `${API_BASE_URL}/portal/register`;
export const POST_FORGOT_PASSWORD_API = () => `${API_BASE_URL}/portal/forgot-password`;
export const POST_RESET_PASSSWORD_API = () => `${API_BASE_URL}/portal/reset-password`;
export const POST_SMS_VERIFY_API = () => `${API_BASE_URL}/portal/sms-confirmation`;
export const POST_SMS_CHANGE_NUMBER_API = () => `${API_BASE_URL}/portal/change-phone`;
export const PORT_SMS_RESEND_CODE_API = () => `${API_BASE_URL}/portal/resend-code`;
export const POST_SEND_EMAIL_VERIFICATION_API = () => `${API_BASE_URL}/portal/confirmation/email`;

// ****************************** Dashboard ****************************** //
// 
export const GET_ADVERTISING_MEDIA_API = (lang) => `${API_BASE_URL}/advertising-medias?language=${lang}`;
export const GET_CHART_API = () => `${API_BASE_URL}/portal/chart?`;

// ****************************** Profile ****************************** //
// Edit Profile
export const USER_AVATAR_API = () => `${API_BASE_URL}/portal/user/avatar`;
export const USER_PROFILE_API = () => `${API_BASE_URL}/portal/users/me`;
export const UPDATE_USER_CONTRACT_API = () => `${API_BASE_URL}/portal/users/update/contract`;

// Verification & Documents
export const USER_DOCUMENTS = () => `${API_BASE_URL}/documents`;

// Funding Methods
export const GET_SETTING_FUNDING_METHOD_API = () => `${API_BASE_URL}/funding-method-settings?enabled=true`;
export const PAYMENT_METHOD_API = () => `${API_BASE_URL}/payment-methods`;

// Security
export const POST_CHANGE_USER_PASSWORD_API = () => `${API_BASE_URL}/portal/user/change-password`;
export const GET_SECURITY_PIN_API = () => `${API_BASE_URL}/portal/user/security-pin`;
export const GET_TWO_FACTOR_ENABLE_API = () => `${API_BASE_URL}/portal/two-factor/enable`;
export const GET_TWO_FACTOR_DISABLE_API = () => `${API_BASE_URL}/portal/two-factor/disable`;
export const POST_TWO_FACTOR_VERIFY_API = () => `${API_BASE_URL}/portal/two-factor/verify`;
export const POST_TWO_FACTOR_LOGIN_API = () => `${API_BASE_URL}/portal/two-factor/login`;

// ****************************** Account ****************************** //
// Create, Get, Delete Account
export const ACCOUNT_API = () => `${API_BASE_URL}/accounts/`;
export const SYNC_WITH_META_API = () => `${API_BASE_URL}/accounts/sync-with-meta`;


// Accounts Actions
export const GET_LEVERAGE_API = (id) => `${API_BASE_URL}/leverages?accountTypeId=${id}`;
export const GET_ACCOUNTS_TYPE_API = () => `${API_BASE_URL}/account-types`;
export const GET_ACCOUNT_OPEN_POSITIONS = (login) => `${API_BASE_URL}/portal/open-positions/${login}`;
export const GET_ACCOUNT_DEALS_HISTORY = (login) => `${API_BASE_URL}/portal/deals/${login}?`;
export const POST_ACCOUNT_DEPOSIT_API = () => `${API_BASE_URL}/portal/accounts/deposit`;
export const POST_ACCOUNT_WITHDRAWAL_API = () => `${API_BASE_URL}/portal/accounts/withdrawal`;
export const POST_UPDATE_ACCOUNT_LEVERAGE_API = (id) => `${API_BASE_URL}/accounts/leverage/${id}`;
export const POST_RESET_ACCOUNT_PASSWORD_API = () => `${API_BASE_URL}/portal/account-reset-password`;
export const POST_CHANGE_ACCOUNT_PASSWORD_API = () => `${API_BASE_URL}/portal/account-change-password`;

// ****************************** Billing ****************************** //
// 
export const TRANSACTIONS_API = () => `${API_BASE_URL}/transactions`;
export const GET_SYSTEM_PAYMENT_METHOD_API = () => `${API_BASE_URL}/system-payment-methods`;
export const POST_WALLET_NUMBER_API = () => `${API_BASE_URL}/portal/wallet`;
export const POST_WALLET_TRANSFER_API = () => `${API_BASE_URL}/portal/wallet/transfer`;
export const TRANSACTIONS_ACCOUNT_TO_USER_API = () => `${API_BASE_URL}/transactions/account-to-user`;
export const TRANSACTIONS_WITHDRAW_TO_USER_API = () => `${API_BASE_URL}/transactions/withdraw-to-user`;
export const TRANSACTIONS_DEPOSIT_TO_WALLET_API = () => `${API_BASE_URL}/transactions/deposit-to-wallet`;
export const TRANSACTIONS_DEPOSIT_TO_ACCOUNT_API = () => `${API_BASE_URL}/transactions/deposit-to-account`;
export const TRANSACTIONS_AUTO_DEPOSIT_TO_WALLET_API = () => `${API_BASE_URL}/transactions/auto-deposit-to-wallet`;


// ****************************** Promotions ****************************** //
//
export const GET_PROMOTIONS = () => `${API_BASE_URL}/promotions`;
export const USER_PROMOTIONS = () => `${API_BASE_URL}/users-promotions`;
export const POST_RENEW_USER_PROMOTIONS = () => `${API_BASE_URL}/users-promotions/renew`;

// ****************************** Partnership ****************************** //
// 
export const GET_AFFILIATE_COMMISSION_API = () => `${API_BASE_URL}/affiliate-commission-rates?_sort=account_type:asc,minLot:asc`;
export const GET_CASH_BACK_API = () => `${API_BASE_URL}/portal/cashback?`;
export const GET_IB_API_OLD = () => `${API_BASE_URL}/portal/ib?`;
export const GET_IB_API = () => `${API_BASE_URL}/ib-users?`;
export const GET_IB_OTHTER_LEVELS_API_OLD = () => `${API_BASE_URL}/portal/ib-other-levels?`;
export const GET_IB_OTHTER_LEVELS_API = () => `${API_BASE_URL}/ib-sublevel-users?`;
export const GET_PAYOUTS_HISTORY_API = () => `${API_BASE_URL}/payouts-histories?`;
export const PUT_PAYOUTS_API = (id) => `${API_BASE_URL}/payouts-histories/paid/${id}`;
export const GET_ADVERTISING_MEDIAKIT_API = (lang, location) => `${API_BASE_URL}/advertising-medias?language=${lang ? lang : 'en'}&location=${location ? location : 'media'}`;


// ****************************** Support ****************************** //
// Faqs
export const GET_FAQS = (faq,lang) => `${API_BASE_URL}/faqs?category=${faq}&language=${lang}`;

// Platform
export const GET_PLATFORM_API = () => `${API_BASE_URL}/platforms/`;

// Tickets
export const GET_ACCOUNT_TICKETS_API = () => `${API_BASE_URL}/tickets/`;
export const PUT_ACCOUNT_TICKET_API = () => `${API_BASE_URL}/tickets/`;
export const POST_ACCOUNT_TICKETS_API = () => `${API_BASE_URL}/tickets/`;
export const POST_ACCOUNT_TICKET_MESSAGE_API = () => `${API_BASE_URL}/ticket-messages/`;
export const GET_TICKET_DEPARTMENTS_API = () => `${API_BASE_URL}/ticket-departments/`;
export const GET_ACCOUNT_TICKET_API = (id) => `${API_BASE_URL}/tickets/${id}`;
export const POST_ACCOUNT_TICKET_FEEDBACK_API = (id) => `${API_BASE_URL}/tickets/feedback/${id}`;

// VPS
export const GET_USER_VPS = () => `${API_BASE_URL}/vps`;
export const GET_HETZNER_VPS = () => `${API_BASE_URL}/vps-hetzner`;
export const PUT_HETZNER_VPS_OFF = (hetznerId) => `${API_BASE_URL}/vps/poweroff/${hetznerId}`;
export const PUT_HETZNER_VPS_ON = (hetznerId) => `${API_BASE_URL}/vps/poweron/${hetznerId}`;
export const PUT_HETZNER_VPS_RESET = (hetznerId) => `${API_BASE_URL}/vps/reset/${hetznerId}`;
export const PUT_HETZNER_VPS_CHANGE_NAME = (hetznerId) => `${API_BASE_URL}/vps/change-name/${hetznerId}`;
export const DELETE_HETZNER_VPS = (hetznerId) => `${API_BASE_URL}/vps/${hetznerId}`;
export const POST_CREATE_HETZNER_VPS = () => `${API_BASE_URL}/vps`;
export const REQUEST_CONSOLE_HETZNER_VPS = (hetznerId) => `${API_BASE_URL}/vps/request-console/${hetznerId}`;