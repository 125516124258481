import {
    SET_USER_DATA,
    SET_AUTHENTICATED,
    SET_PAYMENT_METHODS,
    SET_ROLE,
    SET_LANG,
    SET_USER_ID
} from '../constants/User';

export const setUserData = (data) => {
    return {
        type: SET_USER_DATA,
        payload: data
    }
};

export const setAuthenticated = (auth) => {
    return {
        type: SET_AUTHENTICATED,
        payload: auth
    }
};

export const setPaymentMethods = (methods) => {
    return {
        type: SET_PAYMENT_METHODS,
        payload: methods
    }
};

export const setRole = (role) => {
    return {
        type: SET_ROLE,
        payload: role
    }
};

export const setLang = (lang) => {
    return {
        type: SET_LANG,
        payload: lang
    }
};

export const setUserId = (id) => {
    return {
        type: SET_USER_ID,
        payload: id
    }
};