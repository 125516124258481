import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import {connect, useSelector} from "react-redux";
import TawkTo from 'tawkto-react';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import {onMobileNavToggle} from "redux/actions/Theme";
import {
  DashboardOutlined,
  AppstoreOutlined,
  CloudDownloadOutlined,
  MessageOutlined,
  CustomerServiceOutlined,
  BarChartOutlined,
  IdcardOutlined,
  QuestionCircleOutlined,
  ClusterOutlined,
  GiftOutlined,
  CopyOutlined,
  CreditCardOutlined,
  AreaChartOutlined,
  CalendarOutlined,
  DollarCircleOutlined,
  CloudServerOutlined,
  CrownOutlined
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import moment from "moment";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;


const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    navigationConfig,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const user = useSelector((state)=>state.user.user);
  var startLogin = moment(user?.last_online).toDate();
  var timeNow = new Date();
  if (timeNow.getHours() - startLogin.getHours() >= 12) {
    /*localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REGISTER_TOKEN);
    localStorage.removeItem(CAPTCHA_TOKEN);*/
    window.location.reload();
  }
  const State = useSelector((state)=> state);
  const lang = State?.theme.locale;
  let tawk = null;

  if(lang === "en"){
    tawk = new TawkTo('6487112794cf5d49dc5d2ff0', '1h2npm0qk');
  } else {
    tawk = new TawkTo('6487112794cf5d49dc5d2ff0', '1h2npm0qk');
  }
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig.map(
        (menu) =>
          menu.allowed !== false &&
          (menu.submenu.length > 0 ? (
            <Menu.ItemGroup
              key={menu.key}
              title={
                String(setLocale(localization, menu.title)).toUpperCase() ===
                  "SETTINGS" &&
                String(user?.role) !== "manager" &&
                String(user?.role) !== "admin"
                  ? ""
                  : setLocale(localization, menu.title)
              }
            >
              {menu.submenu
                .filter(
                  (elm) =>
                    String(elm.allowed).includes(String(user?.role)) ||
                    elm.allowed === null
                )
                .map((subMenuFirst) =>
                  subMenuFirst.submenu.length > 0 ? (
                    <SubMenu
                      icon={
                        subMenuFirst.icon ? (
                          <Icon type={subMenuFirst?.icon} />
                        ) : null
                      }
                      key={subMenuFirst.key}
                      title={setLocale(localization, subMenuFirst.title)}
                    >
                      {subMenuFirst.submenu.map((subMenuSecond) => (
                        <Menu.Item key={subMenuSecond.key}>
                          {subMenuSecond.icon ? (
                            <Icon type={subMenuSecond?.icon} />
                          ) : null}
                          <span>
                            {setLocale(localization, subMenuSecond.title)}
                          </span>
                          <Link
                            onClick={() => closeMobileNav()}
                            to={subMenuSecond.path}
                          />
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      disabled={subMenuFirst.disabled}
                      key={subMenuFirst.key}
                    >
                      {subMenuFirst.icon ? (
                        <Icon type={subMenuFirst.icon} />
                      ) : null}

                      {subMenuFirst.click ?
                          <span onClick={()=>tawk.toggle()}>{setLocale(localization, subMenuFirst.title)}</span>
                          :
                          <>
                            <span>{setLocale(localization, subMenuFirst.title)}</span>
                            <Link
                                onClick={() => closeMobileNav()}
                                to={subMenuFirst.path}
                            />
                          </>
                      }
                    </Menu.Item>
                  )
                )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? (
                <Link onClick={() => closeMobileNav()} to={menu.path} />
              ) : null}
            </Menu.Item>
          ))
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization, navigationConfig } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.allowed !== false && menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  const user = useSelector((state)=>state.user.user);
  const navigationConfig = [
    {
      key: "dashboard",
      path: `${APP_PREFIX_PATH}/dashboard`,
      title: "sidenav.dashboard",
      icon: BarChartOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: "dashboard",
          path: `${APP_PREFIX_PATH}/dashboard`,
          title: "sidenav.dashboard",
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
      ],
    },
    {
      key: "personalinfo",
      path: `${APP_PREFIX_PATH}/dashboards`,
      title: "sidenav.personalinfo",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: "extra-pages-setting",
          path: `${APP_PREFIX_PATH}/profile`,
          title: "sidenav.personalinfo.edit",
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: [],
          allowed: null,
        },
        {
          key: "dashboards-accounts-history",
          path: `${APP_PREFIX_PATH}/accounts/trading-accounts`,
          title: "sidenav.dashboard.accounts",
          icon: AppstoreOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
        // {
        //   key: "prop-trading",
        //   path: `${APP_PREFIX_PATH}/prop-trading`,
        //   title: "sidenav.dashboard.prop",
        //   icon: CrownOutlined,
        //   breadcrumb: false,
        //   submenu: [],
        //   allowed: null,
        // },
        {
          key: "copy-trade",
          path: `${APP_PREFIX_PATH}/copy-trade`,
          title: "sidenav.dashboard.copyTrade",
          icon: CopyOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
        {
          key: "apps-vps",
          path: `${APP_PREFIX_PATH}/vps/servers/list`,
          title: "sidenav.accounts.vps",
          icon: CloudServerOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
      ],
    },
    {
      key: "financial",
      path: `${APP_PREFIX_PATH}/dashboards`,
      title: "sidenav.dashboard.financial",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: "dashboards-billing",
          path: `${APP_PREFIX_PATH}/billing`,
          title: "sidenav.dashboard.billing",
          icon: CreditCardOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
        {
          key: "dashboards-deposit",
          path: `${APP_PREFIX_PATH}/billing/add-fund`,
          title: "Deposit",
          icon: DollarCircleOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
        {
          key: "dashboards-withdrawal",
          path: `${APP_PREFIX_PATH}/billing/withdrawal`,
          title: "sidenav.billing.withdrawal",
          icon: DollarCircleOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
      ],
    },
    // {
    //   key: "promotions",
    //   path: `${APP_PREFIX_PATH}/dashboards`,
    //   title: "sidenav.dashboard.promotions.general",
    //   breadcrumb: false,
    //   submenu: [
    //     {
    //       key: "promotions",
    //       path: `${APP_PREFIX_PATH}/promotions`,
    //       title: "sidenav.dashboard.promotions.general",
    //       icon: GiftOutlined,
    //       breadcrumb: false,
    //       submenu: [],
    //       allowed: null,
    //     },
    //   ],
    // },
    {
      key: "partnership",
      path: `${APP_PREFIX_PATH}/dashboards`,
      title: "sidenav.dashboard.partnership",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: "dashboards-partnership",
          path: `${APP_PREFIX_PATH}/partnership`,
          title: "sidenav.dashboard.financial.partnership",
          icon: ClusterOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
      ],
    },
    {
      key: "market",
      path: `${APP_PREFIX_PATH}/dashboards`,
      title: "sidenav.dashboard.market",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: "market-analysis",
          path: `${APP_PREFIX_PATH}/market`,
          title: "sidenav.dashboard.analysis",
          icon: AreaChartOutlined,
          breadcrumb: false,
          submenu: [],
          disabled: user ? !user.market : true,
          allowed: null,
        },
        {
          key: "calendar-economic",
          path: `${APP_PREFIX_PATH}/calendar`,
          title: "sidenav.dashboard.calendar",
          icon: CalendarOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
      ],
    },
    {
      key: "support",
      path: `${APP_PREFIX_PATH}/support`,
      title: "sidenav.support",
      icon: AppstoreOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: "live-chat",
          click: true,
          title: "sidenav.apps.livechat",
          icon: MessageOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
        // {
        //   key: "apps-chat",
        //   path: `${APP_PREFIX_PATH}/support/chat`,
        //   title: "sidenav.apps.chat",
        //   icon: MessageOutlined,
        //   breadcrumb: false,
        //   submenu: [],
        //   disabled: true,
        //   allowed: null,
        // },
        {
          key: "apps-tickets",
          path: `${APP_PREFIX_PATH}/support/tickets`,
          title: "sidenav.apps.ticket",
          icon: CustomerServiceOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
        {
          key: "platform",
          path: `${APP_PREFIX_PATH}/support/platform`,
          title: "sidenav.apps.platform",
          icon: CloudDownloadOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
        {
          key: "Faq",
          path: `${APP_PREFIX_PATH}/support/faq`,
          title: "sidenav.apps.faq",
          icon: QuestionCircleOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
      ],
    },
  ];

  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} navigationConfig={navigationConfig} />
  ) : (
    <TopNavContent {...props} navigationConfig={navigationConfig} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
