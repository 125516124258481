import React, {lazy, Suspense} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import {useSelector} from "react-redux";

export const AppViews = () => {
  const user = useSelector((state)=>state.user.user);

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboard`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/profile`}
          component={lazy(() => import(`./profile`))}
        />
        <Route
            path={`${APP_PREFIX_PATH}/accounts`}
            component={lazy(() => import(`./accounts`))}
        />
        <Route
            path={`${APP_PREFIX_PATH}/prop-trading`}
            component={lazy(() => import(`./proptrading`))}
        />
        <Route
            path={`${APP_PREFIX_PATH}/copy-trade`}
            component={lazy(() => import(`./copytrade`))}
        />
        <Route
            path={`${APP_PREFIX_PATH}/billing`}
            component={lazy(() => import(`./billing`))}
        />
        <Route
            path={`${APP_PREFIX_PATH}/partnership`}
            component={lazy(() => import(`./partnership`))}
        />
        <Route
            path={`${APP_PREFIX_PATH}/promotions`}
            component={lazy(() => import(`./promotions`))}
        />
        {
          user?.market &&
            <Route
                path={`${APP_PREFIX_PATH}/market`}
                component={lazy(() => import(`./market`))}
            />
        }
        <Route
            path={`${APP_PREFIX_PATH}/calendar`}
            component={lazy(() => import(`./calendar`))}
        />
        <Route
            path={`${APP_PREFIX_PATH}/support`}
            component={lazy(() => import(`./support`))}
        />

        <Route
            path={`${APP_PREFIX_PATH}/vps`}
            component={lazy(() => import(`./vps`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
