import antdFaIR from "antd/es/locale/fa_IR";
import faIR from "../locales/fa_IR.json";

const FaLang = {
  antd: antdFaIR,
  locale: "fa-IR",
  messages: {
    ...faIR,
  },
};
export default FaLang;
